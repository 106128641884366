import React, { useState } from "react";
import profile_icon from "../../assets/profile_icon.png";
import { EditOutlined, LoadingOutlined, PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { Modal, Button, Form, Input, Row, Col, Divider, Switch } from "antd";
import ModelDropdown from "../../Components/ModelDropdown/ModelDropdown";
import VoiceSettings from "./VoiceSettings";
import axios from "axios";
import { baseUrl } from "../../utils/baseUrl";
import ElevenLabsDropdown from "../../Components/VoiceDropdown/ElevenLabsDropdown";
import { toast, ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Settings.css'
import Header from "../../Components/Header/Header";

const plainText = ` Shkale (pronounced to rhyme with “kale”) my AI journal assistant. The name “Shkale” comes from the Irish language word “scéil” which means “story” in english. The spelling is different to allow non irish speakers pronounce it properly.`

const Settings = () => {

  const token = localStorage.getItem("token");
  const [activeModel, setActiveModel] = useState("Gemini");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const [userDetail, setUserDetail] = useState(userInfo);
  const [activeTone, setActiveTone] = useState(userInfo.tone);
  const [elevenLabsVoice, setElevenLabsVoice] = useState(userInfo?.elevenLabsAudio || false);
  const [audio, setAudio] = useState(null);
  const [audioLoader, setAudioLoader] = useState(false);

  const showModal = () => {
    form.setFieldsValue({
      name: userInfo.name,
      email: userInfo.email,
      bio: userInfo.bio,
      city: userInfo.city || userInfo.location.county,
      state: userInfo.state || userInfo.location.state,
      zip: userInfo.zip || userInfo.location.postcode,
      country: userInfo.country || userInfo.location.country,
    });
    setIsModalVisible(true);
  };

  const handleVoiceChange = (event) => {
    const voices = window.speechSynthesis.getVoices();
    setVoice(voices.find((v) => v.name === event.target.value));
  };

  const handlePitchChange = (event) => {
    setPitch(parseFloat(event.target.value));
  };

  const handleRateChange = (event) => {
    setRate(parseFloat(event.target.value));
  };

  const handleVolumeChange = (event) => {
    setVolume(parseFloat(event.target.value));
  };
  const handleModelChange = (modelKey) => {
    console.log("Selected Model:", modelKey);
    updateConfiguration({ aiModel: modelKey });
    // Update the active model or perform any other action
    setActiveModel(modelKey);
  };
  const filterNullValues = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      if (obj[key] !== null && obj[key] !== undefined) {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  };
  const updateConfiguration = async (values) => {
    const { data } = await axios({
      method: "POST",
      url: `${baseUrl}/chat-api/saveConfigurations`,
      headers: {
        "Content-Type": "application/json",
        authtoken: token,
      },
      data: {
        token: token,
        ...filterNullValues(values),
      },
    });
    toastMessage("success")
    setUserDetail(data.data);
    localStorage.setItem("userInfo", JSON.stringify(data.data));

    return data;
  };
  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        console.log("Form Values:", values);
        const data = await updateConfiguration(values);
        console.log("result------", data);

        setLoading(false);
        setIsModalVisible(false);
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const aiTones = [
    "Formal",
    "Friendly",
    "Concise",
    "Supportive",
    "Professional",
    "Informative",
    "Neutral",
    "Casual",
    "Optimistic",
    "Humorous",
    "Playful",
    "Instructive",
    "Emphatic",
  ];

  const handleEnableElevenLab = async (e) => {
    try {
      setElevenLabsVoice(e)
      updateConfiguration({ elevenLabsAudio: e })
    } catch (error) {
      console.error("Error into the handleEnableElevenLabs")
    }
  }
  const toastMessage = (message) => {
    if (message == "success") {
      toast('Success', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
    }
  }
  const streamTextToAudio = async (selectedElevenLabsVoiceId) => {

    const apiKey = 'sk_462d4e3995209a67961f78f2872578c92485205faf01bb19';
    const textData = { text: plainText };
    setAudioLoader(true)
    try {
      const response = await axios({
        method: 'post',
        url: `https://api.elevenlabs.io/v1/text-to-speech/${selectedElevenLabsVoiceId || "onwK4e9ZLuTAKqWW03F9"}/stream`,
        headers: {
          'Content-Type': 'application/json',
          'xi-api-key': apiKey,
        },
        data: textData,
        responseType: 'blob',
      });

      const audioBlob = response.data;
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      audio.play();
      audio.onended = () => {
        URL.revokeObjectURL(audioUrl);
        setAudio(null);
      };
      setAudioLoader(false)
      setAudio(audio);
    } catch (error) {
      setAudioLoader(false)
      console.error('Error streaming audio:', error);
    }
  };
  const stopPlaying = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0; // Reset audio to the beginning
      setAudio(null)
    }
  }
  return (
    <div className="flex flex-col flex-1 bg-[white] mt-1 lg:ml-4 lg:mr-0 lg:mr-4  rounded-2xl lg:rounded-none lg:rounded-s-2xl  border-r border-gray-300  ">
      <Header title="Settings" />
      <div className="p-8 h-screen overflow-y-auto">
        <div >
          <div className="font-semibold mb-5">My Profile</div>
          <div>
            <div className="my-2 font-medium border text-gray-500 border-gray-300 rounded-lg bg-white">
              <div className="flex flex-row gap-6 p-4">
                <div>
                  <img
                    src={profile_icon}
                    alt="Logo"
                    align="middle"
                    width="100px"
                    height="100px"
                    className=""
                  />
                </div>
                <div className="flex flex-col gap-1 w-full">
                  <div className="flex justify-between ">
                    <div className="text-black font-medium">{userInfo.name}</div>
                    <div
                      className="border text-[#168900] border-[#168900] rounded bg-whit px-4 py-1 cursor-pointer"
                      onClick={() => showModal()}
                    >
                      Edit <EditOutlined />
                    </div>
                  </div>
                  <div className="text-black text-sm font-normal">
                    {userInfo.bio}
                  </div>
                  <div className="text-gray-400 text-sm font-normal">
                    {userInfo?.location && <svg class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>}
                    {userInfo.location?.county}
                    {", "}
                    {userInfo.location?.state}
                    {", "}
                    {userInfo.location?.postcode}
                    {", "}
                    {userInfo.location?.country}
                  </div>
                </div>
              </div>
            </div>
            <Modal
              footer={[
                <Button key="back" onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={handleOk}
                  className="bg-[#168900] text-white"
                >
                  Save
                </Button>,
              ]}
              className="overflow-hidden"
              title="Edit Profile"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              // closable={false} // Hide the close icon
              confirmLoading={loading}
              cancelButtonProps={{ style: { color: "red", borderColor: "red" } }}
              okButtonProps={{
                style: { backgroundColor: "#168900", borderColor: "#168900" },
              }}
            >
              <div className="flex justify-center mb-4">
                <div className="relative">
                  <img
                    src={profile_icon}
                    alt="Profile Icon"
                    align="middle"
                    width="100px"
                    height="100px"
                    className="rounded-full"
                  />
                  <EditOutlined
                    className="absolute bottom-0 right-0 bg-white rounded-full p-1 border border-gray-300"
                    style={{ fontSize: "16px", color: "#168900" }}
                  />
                </div>
              </div>
              <div className="modal-content h-80 overflow-y-auto">
                <Form form={form} layout="vertical" name="userForm">
                  <Form.Item
                    name="name"
                    label={<span className="text-gray-400">Name</span>}
                    rules={[
                      { required: true, message: "Please input your name!" },
                    ]}
                  >
                    <Input className="p-2 border border-gray-300 rounded-lg bg-white text-gray-500" />
                  </Form.Item>
                  {/* <Form.Item
              name="email"
              label={<span className="text-gray-400">Email</span>}
              rules={[
                { required: true, message: "Please input your email!" },
                { type: "email", message: "Please enter a valid email!" },
              ]}
            >
              <Input className="p-2 border border-gray-300 rounded-lg bg-white text-gray-500" />
            </Form.Item> */}
                  <Form.Item
                    name="bio"
                    label={<span className="text-gray-400">Bio</span>}
                  >
                    <Input className="p-2 border border-gray-300 rounded-lg bg-white text-gray-500" />
                  </Form.Item>

                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="city"
                        label={<span className="text-gray-400">City</span>}
                      >
                        <Input className="p-2 border border-gray-300 rounded-lg bg-white text-gray-500" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="state"
                        label={<span className="text-gray-400">State</span>}
                      >
                        <Input className="p-2 border border-gray-300 rounded-lg bg-white text-gray-500" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="zip"
                        label={
                          <span className="text-gray-400">Zip/Postal Code</span>
                        }
                      >
                        <Input className="p-2 border border-gray-300 rounded-lg bg-white text-gray-500" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="country"
                        label={<span className="text-gray-400">Country</span>}
                      >
                        <Input className="p-2 border border-gray-300 rounded-lg bg-white text-gray-500" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Modal>
          </div>
        </div>
        <div>
          <div className="font-semibold mb-5">Customize your shkaleChat</div>
          <div className="my-2 font-medium border text-gray-500 border-gray-300 rounded-lg bg-white">
            <div className="p-4">
              <div className="">
                Choose the tone your Journal assistant chat uses
                <Divider />
              </div>

              <div className="mb-4 flex flex-wrap gap-2">
                {aiTones.map((tone, index) => (
                  <div
                    key={index}
                    onClick={async () => {
                      await updateConfiguration({ tone: tone });
                      setActiveTone(tone);
                    }}
                    className={`${userDetail.tone === tone
                      ? "bg-[#168900] text-white"
                      : "bg-white text-[#168900] "
                      } px-2 py-1 cursor-pointer font-normal border  border-gray-300 rounded-full  inline-block`}
                  >
                    {tone}
                  </div>
                ))}
              </div>
              <Divider />
              <div className="">
                Choose Your AI Brain: Select a Model for Journal assistant Chat
                <Divider />
              </div>
              <ModelDropdown
                activeModel={userDetail.aiModel}
                handleModelChange={handleModelChange}
              />
            </div>
          </div>
        </div>
        <div className="font-semibold mb-5 mt-2">
          Use Eleven Labs Voice: &nbsp;
          <Switch
            checked={elevenLabsVoice}
            onChange={handleEnableElevenLab}
            className={elevenLabsVoice ? "ant-slider-track" : ""}
          />
        </div>
        {elevenLabsVoice ?
          <div className="my-2 font-medium border text-gray-500 border-gray-300 rounded-lg bg-white">
            <div className="p-4">
              <ElevenLabsDropdown defaultVoice={userDetail.elevenLabsAudioId}
                updateConfiguration={updateConfiguration}
              />
              {audio ? <PauseCircleOutlined onClick={() => stopPlaying()} className="play-btn" />
                : audioLoader ? <LoadingOutlined className="play-btn" /> : <PlayCircleOutlined onClick={() => streamTextToAudio(userDetail.elevenLabsAudioId)} className="play-btn" />}
            </div>
          </div>
          : <><div>
            <div className="font-semibold mb-5">
              Personalize Your Speech: Configure Voice Settings
            </div>

            <div className="my-2 font-medium border text-gray-500 border-gray-300 rounded-lg bg-white">
              <div className="p-4">
                <VoiceSettings defaultVoice={userDetail.voice} />
              </div>
            </div>
          </div></>}
        <ToastContainer />
      </div>
    </div>
  );
};

export default Settings;
